import { MfeComponent } from 'components/MfeComponent';
import { plannerMfeUrl } from 'config/api';
import React from 'react';
import { useSelector } from 'react-redux';
import { getFeatureFlags } from 'selectors/featureFlags';
import { getPermissions } from 'selectors/permissions';
import session from '../../config/session';
import api from 'config/api';

export const MyPlanner2Route = () => {
  const { data, isValid, jwt } = session;
  const userSession = { userSession: data, isValid };
  const { currentUser, featureFlags, permissions } = useSelector((state) => {
    return {
      currentUser: (state as any).auth.currentUser,
      featureFlags: getFeatureFlags(state),
      permissions: getPermissions(state),
    };
  });
  return (
    <MfeComponent
      url={plannerMfeUrl}
      module="./PlannerMfe"
      remote="ps_mfe_naviance_planner"
      moduleType="module"
      initializationData={{
        // Any data you want to pass to the MFE
        apiUrl: api.host,
        jwt,
        currentUser,
        userSession,
        featureFlags,
        permissions,
      }}
    />
  );
};
