import React from 'react';
import { createSelector } from 'reselect';
import { isAllowed, isFeatureFlagAllowed } from 'utils/permissions';
import { getPermissions } from './permissions';
import { getResources } from './home';
import { getFeatureFlags } from './featureFlags';
import { getEditableUser, getCurrentUser, isParentUser } from 'selectors/auth';
import { getHighSchool } from 'selectors/highschool';
import { HSPermissions } from 'types/permissions';
import type { ResourcesLink } from 'types/home';
import { localOpportunities } from 'selectors/localOpportunities';

interface FeatureFlags {
  [x: string]: boolean;
}

enum ResourceType {
  TEST_PREP = 'navianceTestPrep',
  CURRICULUM = 'curriculumLink',
  X2VOL = 'x2Vol',
}

type Link = {
  to: string;
  label: string;
};

const isPermitted = (item: AuthorizedItem, permissions: HSPermissions) => {
  if (permissions.role === 'STUDENT' && permissions.parentId) {
    return item.notAllowedFor !== 'PARENT';
  }
  return item.notAllowedFor !== permissions.role;
};

export const isAuthorized = <T extends AuthorizedItem>(
  item: T,
  permissions: HSPermissions,
  featureFlags: FeatureFlags
): boolean => {
  if (
    !isAllowed({
      acl: permissions,
      group: item.aclGroup,
      target: item.aclTarget,
      checkFor: item.aclTargetType,
    })
  ) {
    return false;
  }
  if (!isPermitted(item, permissions)) {
    return false;
  }
  if (item.featureFlag) {
    if (Array.isArray(item.featureFlag)) {
      const notAllowed = item.featureFlag.some(
        (flag) => !isFeatureFlagAllowed(flag, featureFlags)
      );
      if (notAllowed) {
        return false;
      }
    } else if (!isFeatureFlagAllowed(item.featureFlag, featureFlags)) {
      return false;
    }
  }
  if (item.disabledFeatureFlag) {
    if (isFeatureFlagAllowed(item.disabledFeatureFlag, featureFlags)) {
      return false;
    }
  }
  return true;
};

const careerExplorationLinks: any = [
  {
    to: '/careers/favorites',
    label: 'Favorite Careers and Clusters',
    icon: 'heart-full',
  },
  {
    to: '/careers2/explore',
    label: 'Explore Careers',
  },
  {
    to: '/careers2/explore/clusters',
    label: 'Explore Clusters and Pathways',
  },
];

const careersFavoritesLinks: any = [
  {
    to: '/careers/favorites',
    label: 'Favorite Careers and Clusters',
    icon: 'heart-full',
    featureFlag: 'featureCareers2Explore',
  },
  {
    to: '/careers2/explore',
    label: 'Explore Careers',
    featureFlag: 'featureCareers2Explore',
  },
  {
    to: '/careers2/explore/clusters',
    label: 'Explore Clusters and Pathways',
    featureFlag: 'featureCareers2Explore',
  },
  {
    to: '/job-search',
    label: 'Search Job Opportunities',
    aclGroup: 'careers',
    aclTarget: 'headed2_job_search',
    featureFlag: 'featureNavianceStudentHeaded2JobSearch',
  },
  {
    to: '/careers2/explore/opportunities',
    label: 'Find Work-Based Learning',
    aclGroup: 'careers',
    aclTarget: 'work_based_learning',
    featureFlag: 'featureSharedWbl',
  },
  {
    to: '/careers/roadtripnation',
    label: 'Explore Roadtrip Nation Videos',
    aclGroup: 'careers',
    aclTarget: 'roadtrip_nation_interview_archive',
  },
];

const addLinkIfNotExists = (links: Link[], link: Link) => {
  const exists = links.find((l) => l.to === link.to && l.label === link.label);
  if (!exists) {
    links.push(link);
  }
};

export const authorizeNavigation = (
  sections: NavSection[],
  permissions: HSPermissions,
  featureFlags: FeatureFlags,
  resources: ResourcesLink[],
  localOpportunitiesExist: boolean
): NavSection[] => {
  if (localOpportunitiesExist) {
    const localOpportunitiesLink = {
      to: '/careers2/sponsorship/profile',
      label: 'Explore Local Opportunities',
      featureFlag: 'releaseNavianceStudentHeaded2StateSponsorship',
      aclGroup: 'careers',
      aclTarget: 'state_sponsorship',
    };
    addLinkIfNotExists(careerExplorationLinks, localOpportunitiesLink);
    addLinkIfNotExists(careersFavoritesLinks, localOpportunitiesLink);
  }
  return filterPermittedItem(sections, permissions, featureFlags, resources)
    .map((section) => ({
      ...section,
      menus: filterPermittedMenus(section.menus, permissions, featureFlags, resources),
    }))
    .filter((s) => s.label === 'Home' || !!s.menus.length);
};

export const filterPermittedMenus = (
  menus: NavMenu[],
  permissions: HSPermissions,
  featureFlags: FeatureFlags,
  resources: ResourcesLink[]
): NavMenu[] =>
  filterPermittedItem(menus, permissions, featureFlags, resources)
    .map((menu) => ({
      ...menu,
      topLinks:
        menu.topLinks &&
        filterPermittedItem(menu.topLinks, permissions, featureFlags, resources),
      links:
        menu.links &&
        filterPermittedItem(menu.links, permissions, featureFlags, resources),
      bottomMenu:
        menu.bottomMenu &&
        filterPermittedMenus(menu.bottomMenu, permissions, featureFlags, resources),
    }))
    .filter(
      (m) =>
        (!!m.links && !!m.links.length) ||
        (!!m.topLinks && !!m.topLinks.length) ||
        (!!m.bottomMenu && !!m.bottomMenu.length)
    );

export const filterPermittedItem = <T extends AuthorizedItem>(
  items: T[],
  permissions: HSPermissions,
  featureFlags: FeatureFlags,
  resources: ResourcesLink[]
): T[] =>
  items
    .map((item) => {
      if (item.resourceType) {
        const resource =
          resources && resources.filter((r) => r.type === item.resourceType)[0];
        item.to = (resource && resource.url) || '';
      }
      return item;
    })
    .filter((item) => item.to !== '' && isAuthorized(item, permissions, featureFlags));

interface AuthorizedItem {
  'data-test-id'?: string;
  featureFlag?: string | string[]; // when provided a list, all flags must be ON to be active
  disabledFeatureFlag?: string | string[]; // Active only when the feature flag/ALL flags in an array are OFF
  aclGroup?: string;
  aclTarget?: string | string[];
  notAllowedFor?: string;
  neededPermission?: string | string[];
  aclTargetType?: string;
  resourceType?: ResourceType;
  to?: string;
  openInNewTab?: boolean;
}

export interface NavSection extends AuthorizedItem {
  label: string;
  requiresChildren?: boolean;
  menus: NavMenu[];
  // to: string; // This will determine the active nav item, does not navigate to it
  icon?: string;
}

export interface NavLink extends AuthorizedItem {
  // to: string;
  label: string;
  icon?: string;
  target?: string;
}

export interface NavMenu extends AuthorizedItem {
  label?: string;
  icon?: string;
  links: NavLink[];
  topLinks?: NavLink[];
  bottomMenu?: NavMenu[];
  menuFooter?: React.ReactNode;
}

const newNavigation = createSelector([], () => [
  {
    label: 'Home',
    icon: 'weekend-house',
    to: '/main',
    menus: [],
  },
  {
    label: 'Self-Discovery',
    'data-test-id': 'navbar_assessments',
    featureFlag: 'featureSharedAssessments',
    aclGroup: 'header',
    aclTarget: 'self_discovery',
    to: '/careers2/assessments',
    matchNavLinks: ['/careers2/assessments', '/about-me/strengths-explorer'],
    menus: [
      {
        links: [
          {
            label: 'Self-Discovery Home',
            icon: 'weekend-house',
            featureFlag: 'featureSharedAssessments',
            to: '/careers2/assessments',
          },
          {
            label: 'Career Cluster Finder',
            to: '/careers/cluster-finder',
            aclGroup: 'self-discovery',
            aclTarget: 'career_cluster_finder',
          },
          {
            to: '/careers/interest-profiler',
            label: 'Career Interest Profiler',
            aclGroup: 'self-discovery',
            aclTarget: 'career_interest_profiles',
          },
          {
            to: '/careers/career-key/',
            label: 'Career Key',
            aclGroup: 'self-discovery',
            aclTarget: 'career_key',
            featureFlag: 'careerKey',
          },
          {
            label: 'Intelligences®',
            to: '/about-me/achieveworks/MI',
            aclGroup: 'self-discovery',
            aclTarget: 'mi_advantage_2',
          },
          {
            label: 'Learning and Productivity®',
            to: '/about-me/achieveworks/LSI',
            aclGroup: 'self-discovery',
            aclTarget: 'learning_style_2',
          },
          {
            label: 'Personality®',
            to: '/about-me/achieveworks/DWYA',
            aclGroup: 'self-discovery',
            aclTarget: 'do_what_you_want_2',
            featureFlag: 'doWhatYouAre',
          },
          {
            label: 'Skills®',
            to: '/careers2/assessments/achieveworks/skills',
            aclGroup: 'self-discovery',
            aclTarget: 'aw_skills',
            featureFlag: 'awSkills',
          },
          {
            to: '/about-me/strengths-explorer',
            label: 'StrengthsExplorer®',
            aclGroup: 'self-discovery',
            aclTarget: 'strengths_explorer',
          },
        ],
      },
    ],
  },
  {
    label: 'Courses',
    'data-test-id': 'navbar_course_planner_next',
    aclGroup: 'courses',
    aclTarget: 'course_planner_next',
    to: '/course-planner-next/',
    menus: [
      {
        links: [
          {
            to: '/course-planner-next/favorites',
            label: `Favorite Courses`,
            icon: 'heart-full',
          },
          {
            to: '/course-planner-next/catalog',
            label: 'Explore Course Catalog',
          },
          {
            to: '/course-planner-next/plans',
            label: `Manage Course Plans`,
            aclGroup: 'courses',
            aclTarget: 'view_and_edit_course_plans',
          },
        ],
      },
    ],
  },
  {
    label: 'Careers',
    'data-test-id': 'navbar_careers',
    aclGroup: 'header',
    aclTarget: 'careers',
    to: '/careers2/explore',
    matchNavLinks: [
      '/careers2/explore',
      '/careers/favorites',
      '/careers2/occupation',
      '/careers/roadtripnation',
      '/job-search',
      '/careers2/sponsorship',
    ],
    featureFlag: ['featureNavianceStudentHomeV2'],
    menus: [
      {
        label: 'Career Exploration',
        links: careerExplorationLinks,
      },
      {
        label: 'Military Exploration',
        featureFlag: 'releaseCareers2RelatedMilitaryCareers',
        links: [
          {
            to: '/careers/favorites/Military%20Branches',
            label: 'Favorite Military Branches',
            icon: 'heart-solid',
          },
          {
            to: '/careers2/explore/military',
            label: 'Explore Military Branches',
          },
        ],
      },
      {
        label: 'Experiential Exploration',
        links: [
          {
            to: '/job-search',
            label: 'Search Job Opportunities',
            aclGroup: 'careers',
            aclTarget: 'headed2_job_search',
            featureFlag: 'featureNavianceStudentHeaded2JobSearch',
          },
          {
            to: '/careers2/explore/opportunities',
            label: 'Work Based Learning',
            aclGroup: 'careers',
            aclTarget: 'work_based_learning',
          },
          {
            to: '/careers/roadtripnation',
            label: 'Explore Roadtrip Nation Videos',
            aclGroup: 'careers',
            aclTarget: 'roadtrip_nation_interview_archive',
          },
          {
            label: 'x2vol',
            featureFlag: ['x2Vol', 'featureNavianceStudentHomeV2'],
            notAllowedFor: 'PARENT',
            resourceType: ResourceType.X2VOL,
            openInNewTab: true,
          },
        ],
      },
    ],
  },
  {
    label: 'Careers',
    'data-test-id': 'navbar_careers',
    aclGroup: 'header',
    aclTarget: 'careers',
    to: '/careers2/explore',
    matchNavLinks: [
      '/careers2/explore',
      '/careers/favorites',
      '/careers2/occupation',
      '/careers/roadtripnation',
      '/job-search',
      '/careers2/sponsorship',
    ],
    disabledFeatureFlag: ['featureNavianceStudentHomeV2'],
    menus: [
      {
        links: careersFavoritesLinks,
      },
    ],
  },
  {
    label: 'Colleges',
    'data-test-id': 'navbar_colleges',
    aclGroup: 'header',
    aclTarget: 'colleges',
    to: '/colleges',
    matchNavLinks: ['/colleges', '/about-me/test-scores'],
    menus: [
      {
        label: 'Find Your fit',
        topLinks: [
          {
            label: 'College Home',
            icon: 'weekend-house',
            to: '/colleges',
            aclGroup: 'header',
            aclTarget: 'colleges',
          },
        ],
        links: [
          {
            label: 'SuperMatch College Search',
            to: '/colleges/supermatch-next',
            aclGroup: 'colleges',
            aclTarget: 'super_match_college_search',
            featureFlag: 'superMatchNext',
            'data-test-id': 'super_match_next',
          },
          {
            label: 'College Match',
            to: '/colleges/match',
            aclGroup: 'colleges',
            aclTarget: [
              'college_match_active_match',
              'college_match_admission_history',
              'college_match_universal_overlaps',
            ],
            featureFlag: 'collegeMatch',
          },
          {
            label: 'College Events',
            to: '/colleges/events',
            aclGroup: 'colleges',
            aclTarget: 'active_match_events',
            featureFlag: 'collegeEvents',
          },
          {
            label: 'Scattergrams',
            to: '/colleges/scattergram',
            aclGroup: 'colleges',
            aclTarget: 'scattergrams',
            notAllowedFor: 'GUEST',
          },
          {
            label: 'Advanced College Search',
            to: '/colleges/search',
            aclGroup: 'colleges',
            aclTarget: 'college_search',
            notAllowedFor: 'GUEST',
          },
          {
            label: 'College Lookup',
            to: '/colleges/college-lookup',
            aclGroup: 'colleges',
            aclTarget: 'college_search',
            notAllowedFor: 'GUEST',
          },
        ],
      },
      {
        label: 'Research Colleges',
        links: [
          {
            label: `Colleges I'm Thinking About`,
            to: '/colleges/application/considering',
            icon: 'heart-full',
            notAllowedFor: 'GUEST',
          },
          {
            label: 'College Visits',
            to: '/colleges/visits',
            aclGroup: 'colleges',
            aclTarget: 'college_visits',
            featureFlag: 'collegeVisits',
          },
          {
            label: 'College Compare',
            to: '/colleges/compare',
            aclGroup: 'colleges',
            aclTarget: ['college_statistics', 'college_compare'],
            featureFlag: 'collegeCompare',
          },
          {
            label: 'College Resources',
            to: '/colleges/resources',
            aclGroup: 'colleges',
            aclTarget: 'resources',
            featureFlag: 'collegesResources',
          },
          {
            label: 'Acceptance History',
            to: '/colleges/acceptance-history',
            aclGroup: 'colleges',
            aclTarget: 'acceptance_history',
          },
          {
            label: 'Enrichment Programs',
            to: '/colleges/enrichment-programs',
            aclGroup: 'colleges',
            aclTarget: 'enrichment_programs',
            featureFlag: 'enrichmentPrograms',
          },
          {
            label: 'College Maps',
            to: '/colleges/maps',
            aclGroup: 'colleges',
            aclTarget: 'college_maps',
          },
        ],
      },
      {
        label: 'Apply to College',
        links: [
          {
            label: `Colleges I'm Applying to`,
            to: '/colleges/applying-to',
            notAllowedFor: 'GUEST',
          },
          {
            label: 'Manage Transcripts',
            to: '/colleges/transcripts',
            aclGroup: 'transcripts',
            aclTarget: 'display',
            featureFlag: 'transcripts',
          },
          {
            label: 'Letters of Recommendation',
            to: LOR_APP_URL,
            aclGroup: 'colleges',
            aclTarget: 'request_letters_of_recommendation',
            featureFlag: 'lettersOfRecommendation',
          },
          {
            label: 'Test Scores',
            to: '/about-me/test-scores',
            featureFlag: 'testScores',
            notAllowedFor: 'GUEST',
          },
        ],
      },
      {
        label: 'Scholarships & Money',
        links: [
          {
            label: 'College-Specific Scholarships',
            to: '/colleges/scholarships/colleges',
            aclGroup: 'colleges',
            aclTarget: 'scholarships',
            featureFlag: 'featureNavianceStudentScholarshipsMicroApp',
            notAllowedFor: 'GUEST',
          },
          {
            label: "Scholarships I'm Applying To",
            to: '/colleges/scholarships/applications',
            aclGroup: 'colleges',
            aclTarget: 'scholarships',
            featureFlag: 'scholarships',
            notAllowedFor: 'GUEST',
            disabledFeatureFlag: 'featureNavianceStudentScholarshipsMicroApp',
          },
          {
            label: 'Scholarship List',
            to: '/colleges/scholarships',
            aclGroup: 'colleges',
            aclTarget: 'scholarships',
            featureFlag: 'scholarshipList',
            disabledFeatureFlag: [
              'releaseSharedScholarships',
              'featureNavianceStudentScholarshipsMicroApp',
            ],
          },
          {
            label: 'Scholarship List',
            to: '/colleges/scholarships/colleges',
            aclGroup: 'colleges',
            aclTarget: 'scholarships',
            featureFlag: ['scholarshipList', 'releaseSharedScholarships'],
            disabledFeatureFlag: 'featureNavianceStudentScholarshipsMicroApp',
          },
          {
            label: 'National Scholarship Search',
            to: '/colleges/scholarships/national',
            target: '_blank',
            aclGroup: 'colleges',
            aclTarget: 'national_scholarship_search',
            featureFlag: 'nationalScholarship',
          },
          {
            label: 'Scholarship Search',
            to: '/colleges/scholarships/new/search',
            aclGroup: 'colleges',
            aclTarget: 'scholarships',
            featureFlag: 'featureNavianceStudentScholarshipsMicroApp',
            disabledFeatureFlag: ['releaseSharedScholarshipDisableForMaintenance'],
          },
          {
            label: 'Favorite Scholarships',
            to: '/colleges/scholarships/new/applications',
            aclGroup: 'colleges',
            aclTarget: 'scholarships',
            featureFlag: 'featureNavianceStudentScholarshipsMicroApp',
            notAllowedFor: 'GUEST',
            icon: 'heart-full',
            disabledFeatureFlag: ['releaseSharedScholarshipDisableForMaintenance'],
          },
        ],
      },
    ],
  },
  {
    label: 'Planner',
    'data-test-id': 'navbar_planner',
    neededPermission: 'success_planner',
    to: '/my-planner',
    aclGroup: 'header',
    aclTarget: 'success_planner',
    menus: [
      {
        links: [
          {
            label: 'Planner Home',
            icon: 'weekend-house',
            to: '/my-planner',
            aclGroup: 'header',
            aclTarget: 'success_planner',
            disabledFeatureFlag: ['featureNavianceStudentMyPlannerCalendarView'],
          },
          {
            label: 'Tasks',
            to: '/my-planner',
            aclGroup: 'header',
            aclTarget: 'success_planner',
            featureFlag: ['featureNavianceStudentMyPlannerCalendarView'],
          },
          {
            label: 'Goals',
            to: '/my-planner/goals',
            aclGroup: 'header',
            aclTarget: 'success_planner',
          },
          {
            label: 'To-Dos',
            to: '/my-planner/to-do-list',
            aclGroup: 'header',
            aclTarget: 'success_planner',
          },
          {
            label: 'Tasks',
            to: '/my-planner/tasks-assigned-to-me',
            aclGroup: 'header',
            aclTarget: 'success_planner',
            disabledFeatureFlag: ['featureNavianceStudentMyPlannerCalendarView'],
          },
          {
            label: 'Student Readiness Indicators',
            to: '/my-planner/student-readiness-indicators',
            aclGroup: 'success-planner',
            aclTarget: 'view_student_readiness_indicators',
            featureFlag: ['releaseSucceedSrt', 'releaseSharedStudentReadinessIndicators'],
          },
          {
            label: 'Calendar View',
            to: '/my-planner/overview',
            featureFlag: ['featureNavianceStudentMyPlannerCalendarView'],
          },
          {
            label: 'Curriculum',
            featureFlag: ['curriculumLink', 'featureNavianceStudentHomeV2'],
            aclGroup: 'home',
            aclTarget: 'naviance_curriculum',
            notAllowedFor: 'GUEST',
            resourceType: ResourceType.CURRICULUM,
            openInNewTab: true,
          },
          {
            label: 'Test Prep',
            featureFlag: ['testPrepLink', 'featureNavianceStudentHomeV2'],
            aclGroup: 'home',
            aclTarget: 'resources_test_prep',
            notAllowedFor: 'PARENT',
            resourceType: ResourceType.TEST_PREP,
            openInNewTab: true,
          },
        ],
      },
    ],
  },
]);

export const favoriteLinks: NavMenu[] = [
  {
    links: [
      {
        to: '/course-planner-next/favorites',
        label: 'Favorite Courses',
        aclGroup: 'courses',
        aclTarget: 'course_planner_next',
      },
      {
        to: '/careers/favorites',
        label: 'Favorite Careers and Clusters',
        aclGroup: 'header',
        aclTarget: 'careers',
        notAllowedFor: 'GUEST',
      },
      {
        to: '/colleges/application/considering',
        label: `Colleges I'm Thinking about`,
        aclGroup: 'header',
        aclTarget: 'colleges',
        notAllowedFor: 'GUEST',
      },
      {
        to: '/colleges/application/list',
        label: `Colleges I'm Applying To`,
        aclGroup: 'header',
        aclTarget: 'colleges',
        notAllowedFor: 'GUEST',
      },
      {
        to: '/colleges/scholarships/new/applications',
        label: 'Favorite Scholarships',
        featureFlag: 'featureNavianceStudentScholarshipsMicroApp',
        disabledFeatureFlag: 'releaseSharedScholarshipDisableForMaintenance',
        aclGroup: 'header',
        aclTarget: 'colleges',
        notAllowedFor: 'GUEST',
      },
    ],
  },
];

const getLinks = (isParent, studentName) => {
  if (isParent) {
    return [
      {
        to: '/about-me/profile/parent/general',
        label: `My Account`,
      },
      {
        to: '/about-me/profile/general',
        label: `${studentName}'s Account`,
      },
    ];
  }
  return [
    {
      to: '/about-me/profile/general',
      label: `My Account`,
    },
  ];
};

const getProfileMenu = createSelector(
  [getEditableUser, getHighSchool, getCurrentUser, isParentUser],
  (
    { fullName },
    { name: schoolName },
    { firstName: studentName },
    isParent
  ): NavMenu[] => [
    {
      label: 'Account',
      notAllowedFor: 'GUEST',
      topLinks: [
        {
          label: 'About Me Home',
          to: '/about-me/home',
          icon: 'weekend-house',
        },
      ],
      links: getLinks(isParent, studentName),
      menuFooter: (
        <p>
          You are logged in as <b data-navi-donottranslate>{fullName}</b> at{' '}
          <span data-navi-donottranslate>{schoolName}</span>
        </p>
      ),
    },
    {
      notAllowedFor: 'GUEST',
      label: 'Documents and Notes',
      links: [
        {
          label: 'Document Resources',
          to: '/documents/public',
          featureFlag: 'featureNavianceStudentHomeV2',
        },
        {
          label: 'Resume',
          to: '/about-me/resume/build',
        },
        {
          label: 'Goals',
          to: '/my-planner/goals',
        },
        {
          label: 'Portfolio',
          to: '/my-portfolio',
        },
        {
          label: 'My Student Readiness Reports',
          to: '/student-readiness-results',
          featureFlag: 'featureStudentReadinessReportNavigation',
          notAllowedFor: 'STUDENT',
        },
        {
          label: 'Journal',
          to: '/about-me/journal',
        },
        {
          label: 'Test Scores',
          to: '/about-me/test-scores',
          notAllowedFor: 'GUEST',
        },
        {
          label: 'Documents Shared with Me',
          to: '/documents',
        },
      ],
      bottomMenu: [
        {
          label: 'Surveys',
          links: [
            {
              label: 'Surveys from Your School',
              to: '/about-me/surveys',
            },
            {
              label: 'Game Plan Survey',
              to: '/about-me/game-plan',
              aclGroup: 'about-me',
              aclTarget: 'game_plan_survey',
              featureFlag: 'gameplanSurvey',
            },
            {
              label: 'Graduation Survey',
              aclGroup: 'about-me',
              aclTarget: 'graduation_survey',
              to: '/about-me/surveys/graduation',
            },
          ],
        },
      ],
    },
  ]
);

export const getNewMainNavLinks = createSelector(
  [newNavigation, getPermissions, getFeatureFlags, getResources, localOpportunities],
  (
    sections,
    permissions: HSPermissions,
    featureFlags: FeatureFlags,
    resources: ResourcesLink[]
  ) =>
    authorizeNavigation(
      sections,
      permissions,
      featureFlags,
      resources,
      localOpportunities
    )
);

export const getPermittedFavoriteMenu = createSelector(
  [getPermissions, getFeatureFlags, getResources],
  (permissions: HSPermissions, featureFlags: FeatureFlags, resources: ResourcesLink[]) =>
    filterPermittedMenus(favoriteLinks, permissions, featureFlags, resources)
);

export const getPermittedProfileMenu = createSelector(
  [getPermissions, getFeatureFlags, getProfileMenu, getResources],
  (
    permissions: HSPermissions,
    featureFlags: FeatureFlags,
    menu,
    resources: ResourcesLink[]
  ) => filterPermittedMenus(menu, permissions, featureFlags, resources)
);
